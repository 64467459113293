<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Making Logistics Smarter with IoT and AI/ML
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637750135/pexels-rodnae-productions-7363196_rjge0g.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Making Logistics Smarter with IoT and AI/ML
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Nov 24, 2021</p>
            </span>
          </div>

          <h3 class="mb-3 font-weight-bold">
            AI/ML and IoT in Logistics
          </h3>
          <p class="">
            The realisation by corporations of AI's ability to handle the
            complexity of managing a global logistics supply chain is one of the
            main reasons why the adoption of Smart Logistics Operations is
            accelerating in the distribution chain.
          </p>
          <p>
            Let's start at the beginning. The Internet of Things (IoT) and
            Artificial Intelligence (AI) are not quite the same thing, but they
            complement each other perfectly like a merrily married pair. The
            Internet of Things (IoT) is a network of machines that can exchange
            data without a need for human involvement. Artificial intelligence
            (AI) is the process of mimicking human behaviour and intelligence on
            any computer.
          </p>
          <p>
            Because IoT devices can produce large volumes of data, AI can
            interact with them and render it actionable. Rendering data
            actionable involves capturing the most value from it, this takes the
            perspective and creativity of a human to properly handle it, and AI
            does this effectively .
          </p>
          <p>
            AI/ML and IoT are two mind-blowing technologies that are gaining
            momentum in today’s digital world. AI/ML is a powerful tool and it
            just keeps improving and becoming more widely used. Looking around,
            you will find a wide range of applications from self-driving
            automobiles to chatbots and even social networks. We can see how
            swiftly this technology can educate machines to imitate humans, and
            even beat humans in performance.
          </p>
          <p>
            Incorporating IoT and AI/ML tech into logistics processes opens up a
            lot of opportunities to disrupt the existing structure, improve it
            and scale it. Logistics is generally time bound and it features a
            constant race against time to ensure a high customer satisfaction
            rate or meet a production deadline. Digitising with smart IoT and
            AI/ML technology helps to streamline the process and make it more
            efficient.
          </p>

          <figure class="pt-8 pb-4 d-flex justify-center">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746479/pasted_image_0_2_rwme0e.jpg"
              alt=""
              class="pic-size"
            />
          </figure>

          <p>
            With a Smart Digital System, for instance, dispatch can be
            automatically assigned orders that tallies with their route as well
            as be guided on the fastest route they should take, this reduces
            delivery time by a significant margin.
          </p>

          <p>
            When AI/ML and IoT are combined, a company's running expenses may be
            drastically reduced, resulting in a much healthier bottom line.
            Improved efficiency might also help a company. Because IoT devices
            and AI/ML do not require rest, the company's data centre would be
            operational 24 hours a day, 7 days a week to ensure that everything
            runs well.
          </p>
          <p>
            Automation is one virtue of a smart logistics system that can’t be
            underplayed, manual/human labour has its apparent bottlenecks, it is
            limited in speed and prone to error but using AI to automate some of
            these repetitive processes rids the entire process of error, and
            also, makes it more efficient.
          </p>
          <p>
            AI-enabled proactive solutions are improving service quality and
            exceeding customer expectations for on-time and damage-free
            delivery. They're increasing efficiency even further by automating
            compliance processes. As a consequence, expenses are reduced and
            there are fewer issues across the logistics network.
          </p>

          <p>
            Another interesting benefit of a smart logistic system is
            visibility. Visibility of assets through-out the entire value chain
            or the delivery process as in the case of inter-logistics bares a
            significant benefit. Most times, managers don’t get to know about
            eventualities and delays until either the customer is already
            complaining about their order not arriving in time or the unforeseen
            event is already affecting the value chain. Incorporating IoT tech
            and sensors like Real-time Data Loggers and trackers allows the
            managers access to real-time updates on every asset. This gives the
            managers enough room to be proactive, and get ahead of the
            situation.
          </p>

          <figure class="pt-3 pb-4 d-flex justify-center">
            <img
              src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746485/pasted_image_0_3_mzjxdb.jpg"
              alt=""
              width="100%"
              class="pic-size"
            />
          </figure>
          <p>
            Smart logistics also affords organizations the opportunity to make
            smarter, more agile decisions and anticipate problems. With data
            made available by the IoT tech, organisations could base on
            insights, refine their processes to ensure optimal efficiency and
            anticipate challenges.
          </p>
          <p>
            Several opportunities abound when smart tech becomes a major part of
            logistics, for example, having smart cold boxes alone could open up
            an entirely different revenue line for a logistics company.
            Conventional smart trucks are not effective in transporting
            essential Temperature-controlled products like COVID-19 vaccines to
            the last mile because of infrastructural issues especially in the
            rural areas, portable cold boxes is one way to tackle this but
            having cold boxes is not enough to make authorities outsource the
            responsibility of transportation to a logistics company, the fact
            that the integrity of the vaccines needs to be spoken for before
            administering them to the public makes this impossible, a smart cold
            box that can be accessed remotely in real time, store the vaccines
            at the precise temperature range and also generate a quality
            assurance report takes off this barrier, it gives any logistics
            company the leverage they need to get the business.
          </p>
          <p>
            It's 2021 and more and more smart technologies are being adopted by
            logistics companies. In no time, manual labour will be greatly
            reduced and more efficiency will be recorded. With the use of
            appropriate technology, logistics companies are on their way to
            improving the entire system and better control inventory, material
            handling, inbound and outbound transportation management.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
                item:
                  "https://gricd.com/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
              },
            ],
          },
        },
      ],
    };
  },
  created() {
    const readyHandler = () => {
      //   console.log(document.readyState);
      if (document.readyState == "complete") {
        this.loading = false;
        this.loaded = true;
        document.removeEventListener("readystatechange", readyHandler);
      }
    };

    document.addEventListener("readystatechange", readyHandler);

    readyHandler(); // in case the component has been instantiated lately after loading
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // this.loading = false;
        // this.loaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
h3 {
  font-weight: 400;
}
.pic-size {
  width: 28rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
